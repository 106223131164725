<template>
  <div class="ui grid container-2">
    <div class="two column row stackable">
      <div class="column div-1"></div>
      <div class="column div-2">
        <div class="content">
          <h1><span>What is</span> Instapay?</h1>
          <p>
            InstaPay is an Electronic Fund Transfer service that allows you
            to transfer funds between your TayoCash e-wallet and another account
            in another participating BSP-supervised bank or and non-bank e-money issuer, like TayoCash, in the Philippines.
          </p>

          <p>
            That means, you can now send and receive funds or make payments
            via QR PH REAL TIME, 24/7 using your TayoCash mobile app.
          </p>

          <h1><span>What is </span>QR PH?</h1>
          <p>
            QR PH is the National QR Code Standard for digital fund transfers
            and payments using the Quick Response code technology
            of banks, non-bank electronic  money issuers,  like TayoCash,
            and participating merchants and partners.
          </p>

          <p class="last">
            This is through the initiative of the BSP  to promote an interoperable common QR Code that can be scanned and accepted for quick and safe fund transfer and payment requests.
          </p>
          <div class="arrow"/>

          <div class="perform">
            <h2>Perform</h2>
            <h3>TRANSACTIONS ANYTIME, ANYWHERE</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="sixteen wide column">

      <div class="ui grid four column row four">
        <div class="column first">
          <div class="item" />
          <div class="table-data">
            <label>TRANSACTION AMOUNT LIMIT</label>
            <label>NUMBER OF TRANSACTIONS PER DAY</label>
            <label>AVAILABILITY OF SERVICE</label>
            <label>AVAILABILITY OF TRANSACTION <br> AMOUNT (TO RECIPIENT)</label>
            <label>TRANSACTION FEE</label>
          </div>
        </div>
        <div class="column second">
          <div class="item">
            <h4>CASH IN</h4>
            <h5>VIA INSTAPAY</h5>
            <p>From your Bank Account <br>
              or E-Wallet Account
            </p>
          </div>
          <div class="table-data">
            <label>No Limit*</label>
            <label>No Limit*</label>
            <label>24/7</label>
            <label>Real-Time Credit <br> </label>
            <label>FREE</label>
          </div>
        </div>
        <div class="column">
          <div class="item">
            <h4>FUND TRANSFER</h4>
            <h5>VIA INSTAPAY</h5>
            <p>Send/Receive Funds thru a
              Bank Account or E-Wallet Account
              in Participating Banks/EMIs
            </p>
          </div>
          <div class="table-data">
            <label>P50,000 per day*</label>
            <label>No Limit*   </label>
            <label>24/7</label>
            <label>Real-Time Credit</label>
            <label>For Sending: FREE (Waived until Further Notice)
              <br>For Receiving: FREE
            </label>
          </div>
        </div>
        <div class="column">
          <div class="item">
            <h4>PAY VIA QR PH</h4>
            <h5>VIA INSTAPAY</h5>
            <p>To Partners and Merchants
            of Participating Banks/EMIs
            </p>
          </div>
          <div class="table-data">
            <label>No Limit*</label>
            <label>No Limit*</label>
            <label>24/7</label>
            <label>Real-Time Credit <br> </label>
            <label>FREE</label>
          </div>
        </div>
        <div class="note">
          <i>* Subject to your TayoCash Individual and Aggregate Wallet Limit for Linked Accounts</i> |
          <router-link class="link" @click.native="removehash" :to="{ path: '/faq/membership', hash: '#mbs3'}" replace>
            TABLE OF TAYOCASH WALLET LIMITS
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content2',
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    },
  }
}
</script>

<style scoped lang='scss'>
</style>
